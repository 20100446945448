import React, {useEffect, useState} from 'react';
import {
    Table, Td, Th, ImageCell, VideoWrapper, LabelSelect
} from './styles'
import {IconButton} from '@primer/react';
import {RocketIcon} from '@primer/octicons-react'
import {useNavigate, useParams} from 'react-router-dom';

import {UNAUTHORIZED} from '../../../consts';
import {StyledBackText} from '../../../styles';
import {
    getImagesByKeyAndVideoId,
    getImagesByVideoId, markVideoAsAudited, VideoImagesType,
} from "../../../adapters/videos";
import {isEmpty} from "lodash";
import {postGenderAudit} from "../../../adapters/genderAudit";

export type ImageDataType = {
    image: string | null;
    label: string | null;
    key: string | null;
}

const AuditImageList = () => {
    const [images, setImages] = useState<ImageDataType[] | []>([]);
    const navigate = useNavigate();
    const {video_name = ''} = useParams();

    useEffect(() => {
        const getVideoImages = async () => {
            const {data, error}: VideoImagesType = await getImagesByVideoId(video_name);

            if (error) {
                if (error.message === UNAUTHORIZED) {
                    navigate('/login');
                }
                return;
            }

            if (data) {
                data.forEach(async (key) => {
                    try {
                        const imageResponse = await getImagesByKeyAndVideoId(video_name, key.key);
                        const newImage: ImageDataType = {
                            key: key.key,
                            image: imageResponse.data.image,
                            label: imageResponse.data.label,
                        };
                        setImages((prevImages) => [...prevImages, newImage]);
                    } catch (err) {
                        console.error(`Failed to fetch image/label for key ${key}`, err);
                        const failedImage: ImageDataType = {key: key.key, image: null, label: null};
                        setImages((prevImages) => [...prevImages, failedImage]);
                    }
                });
            }
        };

        getVideoImages();
    }, [navigate, video_name]);

    const markVideoComplete = async () => {
        markVideoAsAudited(video_name)

    }

    const handleLabelChange = (currentImage: ImageDataType, newLabel: string) => {
        setImages((images) =>
            images?.map((image) =>
                currentImage.key === image.key ? {...currentImage, label: newLabel} : image
            ) || []
        );

        postGenderAudit(video_name + "/" + currentImage.key + ".jpg", newLabel)
    };

    return (
        <VideoWrapper>
            <StyledBackText onClick={() => navigate(-1)}>{'<'} Go Back</StyledBackText>
            <div>Mark as audited <IconButton aria-label="edit" onClick={() => markVideoComplete()} icon={RocketIcon}/>
            </div>
            {
                !isEmpty(images) ? images.map(image => (
                    <Table>
                        <thead>
                        <tr>
                            <Th>Image</Th>
                            <Th>Update Label</Th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr key={image.key}>
                            <Td>
                                <ImageCell src={`data:image/jpeg;base64,${image.image}`} alt={image.label!}/>
                            </Td>
                            <Td>
                                <LabelSelect
                                    value={image.label!}
                                    onChange={(e) => handleLabelChange(image, e.target.value)}
                                >
                                    <option value="0-20_male">[0-20] Male</option>
                                    <option value="0-20_female">[0-20] Female</option>
                                    <option value="21-40_male">[21-40] Male</option>
                                    <option value="21-40_female">[21-40] Female</option>
                                    <option value="41-60_male">[41-60] Male</option>
                                    <option value="41-60_female">[41-60] Female</option>
                                    <option value="61+_male">[61+] Male</option>
                                    <option value="61+_female">[61+] Female</option>
                                    <option value="n/a">N/A</option>
                                </LabelSelect>
                            </Td>

                        </tr>

                        </tbody>
                    </Table>
                )) : <>Loading...</>
            }

        </VideoWrapper>
    )
}

export default AuditImageList;
