import { Box, Button, Text, TextInput } from '@primer/react';
import styled from 'styled-components';

export const AuthWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

export const AuthBox = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;

  @media screen and (orientation: portrait) {
    width: 90%;
  }
  
  width: 30%;
`;

export const AuthHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 0 0 32px 0;
  width: 100%;
`;

export const AuthHeaderText = styled(Text)`
  padding: 0 6px;
`;

export const AuthFormWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AuthFormText = styled(Text)`
  padding: 0 0 16px 0;
`;

export const AuthTextInput = styled(TextInput)`
  width: 100%;
`;

export const AuthTextError = styled(Text)`
  color: red;
`;

export const AuthButton = styled(Button)`
  width: 100%;
`;

export const AuthSignUpText = styled(Text)`
  padding: 32px 0 0 0;
  text-align: start;
  width: 100%;
`;