import React from 'react';
import {
  ChartContainer,
  ChartWrapper,
  ChartTitle,
} from './styles'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';

// Define the shape of your data items
type DataItem = {
  color: string;
  name: string | undefined;
  value: number;
  percentage?: number;
}
interface PieChart {
  title: string;
  dataItems: DataItem[]
}
interface PieChartProps {
  piecharts: PieChart[]; // Define the prop for passing the metrics array
}

// Colors for the pie charts
const COLORS: string[] = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const PieChartContainer: React.FC<PieChartProps> = ({ piecharts }) => (
  <ChartContainer>
    {piecharts.map((piechart, index) => {
      return (
      <ChartWrapper>
        <ChartTitle>{piechart.title}</ChartTitle>
        <PieChart
          series={[
            {
              // Move this to the api adapter, we need to append the 'label' field for the
              // legend to appear
              data: piechart.dataItems.map(item => ({ ...item, label: item.name })),
            }
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
            },
          }}
          width={400}
          height={200}
          slotProps={{
            legend: {
              position: {
                vertical: 'middle',
                horizontal: 'right',
              },
              markGap: 5,
              itemGap: 10,
            }
          }}
        />
      </ChartWrapper>
    )
  })}
  </ChartContainer>
);

export default PieChartContainer;
