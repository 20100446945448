import styled from 'styled-components';

export const GenderAuditWrapper = styled.div`
  padding: 16px;
`;

export const GenderAuditImageWrapper = styled.div`
  height: auto;
  width: 100%;
  margin-bottom: 16px;
`;

export const GenderAuditButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  > .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  > .button-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: 100%;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    > button {
      margin: 0;
      padding: 12px;
      font-size: 14px;
    }
  }
`;
