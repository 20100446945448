import { Box, Text } from '@primer/react';
import styled from 'styled-components';

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: left;
  width: 60%;

  .dashboard-link-text {
    // UPDATE COLOR WHEN DEFINED
    color: #000;
    text-decoration: none;
  }
`;

export const ImageCell = styled.img`
  max-width: 300px;  /* Increase the size here */
  max-height: 300px; /* Increase the size here */
  object-fit: contain; /* Ensures the image fits within the dimensions without being distorted */
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

export const LabelSelect = styled.select`
  padding: 4px;
  font-size: 14px;
`;
