import styled from "styled-components";
import { Typography } from 'antd';
import { ResponsiveContainer } from "recharts";

const { Title } = Typography;

export const AgeBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AgeBreakdownData = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

export const StyledAgeRange = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`;

export const StyledAgeRangeHeader = styled.div``;
export const StyledAgeRangeData = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledChartWrapper = styled.div`
  height: auto;
  margin: auto;
`;

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin: 30px 0 0 0;
`;

export const StyledTitle = styled(Title)`
  margin: 15px 0;
`;
