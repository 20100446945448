import {API_URL, AUTH_ROUTE, REGISTER_ROUTE, TOKEN_ROUTE} from "./consts";
import { axiosInstance } from "./instance";

type LoginRequestType = {
  username:string;
  password: string;
}

export type LoginResponseType = {
  username:string;
  email: string;
  id: string
}

export type JWTLoginResponseType = {
  access: string;
  refresh: string;
  user: any | null; // If you are also storing user data, adjust this accordingly
};

type RegisterRequestType = {
  email:string;
  username: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
}

export type RegisterResponseType = {
  email:string;
  username: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
}

export async function login({ username, password }: LoginRequestType) {
  try {
    const response: {data: JWTLoginResponseType} = await axiosInstance({
      method: 'post',
      url: `${AUTH_ROUTE}${TOKEN_ROUTE}`,
      data: {
        username,
        password,
      },
    });
    const { access = '', refresh = '' } = response?.data;

    localStorage.setItem('accessToken', access)
    localStorage.setItem('refreshToken', refresh)

    return response?.data ?? {};
  } catch (err: any) {
    if (err?.response?.status === 400) {
      return Promise.reject(err?.response?.data);
    }
  }
};

export async function register({ email, username, password, password2, firstName, lastName }: RegisterRequestType) {
  try {
    const requestBody = {
      email, username, password, password2, first_name: firstName, last_name: lastName,
    }
    const response = await axiosInstance({
      method: 'post',
      url: `${AUTH_ROUTE}${REGISTER_ROUTE}`,
      data: requestBody
    })

    return response;
  } catch (err: any) {
    if (err?.response?.status === 400) {
      return Promise.reject(err?.response?.data);
    }
  }
};
