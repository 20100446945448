import React, {useContext, useState} from 'react';
import { FormControl, Octicon } from '@primer/react'
import { DiamondIcon } from '@primer/octicons-react'
import { isEmpty } from 'lodash';
import { Link, Navigate } from 'react-router-dom';
import { AuthBox, AuthHeader, AuthHeaderText, AuthWrapper, AuthFormWrapper, AuthFormText, AuthTextInput, AuthButton, AuthSignUpText, AuthTextError } from '../styles';
import { JWTLoginResponseType } from '../../../adapters/authentication';
import { authenticationAdapters } from '../../../adapters';


type LoginProps = {
    authenticationToken: JWTLoginResponseType | null,
    setAuthenticationToken: (token: any) => void;
}

const Login = ({authenticationToken, setAuthenticationToken}: LoginProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<any>({});

  const handleLogin = async () => {
    try {
      const resp: JWTLoginResponseType | undefined = await authenticationAdapters.login({ username, password });
      setAuthenticationToken(resp);
    } catch (err) {
      setErrors(err)
    }
  }

  return (
    <AuthWrapper>
      {
        !isEmpty(authenticationToken) ?
          (
            <Navigate to={'/audit/images'} replace />
          ) : (
          <AuthBox sx={{borderWidth: 1, borderStyle: 'solid', borderColor: 'border.default', borderRadius: '2', p: 3}}>
            <AuthHeader>
              <Octicon icon={DiamondIcon} size={16}/>
              <AuthHeaderText>Streetsmart.ai</AuthHeaderText>
            </AuthHeader>
            <AuthFormWrapper>
              <AuthFormText>Log in</AuthFormText>
              {/* TODO: figure out why styled FormControl doesn't work */}
              <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                <FormControl.Label>Username</FormControl.Label>
                <AuthTextInput value={username} onChange={(e) => setUsername(e.currentTarget.value)} />
              </FormControl>
              {/* TODO: figure out why styled FormControl doesn't work */}
              <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                <FormControl.Label>Password</FormControl.Label>
                <AuthTextInput type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
              </FormControl>
              { errors && errors.non_field_errors && errors.non_field_errors.map((error: string) => <AuthTextError>{error}</AuthTextError>)}
              <AuthButton onClick={handleLogin}>Login</AuthButton>
            </AuthFormWrapper>
            <AuthSignUpText>Don't have an account? <Link to="/register">Sign up</Link></AuthSignUpText>
          </AuthBox>
        )
    }
    </AuthWrapper>

  )
}

export default Login
