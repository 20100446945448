import React, { useEffect, useState } from 'react';
import { DashboardBox, DashboardBoxDetails, DashboardBoxLinks, DashboardWrapper } from './styles'
import { Heading, Text } from '@primer/react';
import { Link, useNavigate } from 'react-router-dom';
import { PropertiesType, PropertiesDataType, getProperties } from '../../adapters/properties';
import { isEmpty } from 'lodash';
import { UNAUTHORIZED } from '../../consts';

const Dashboard = () => {
  const [properties, setProperties] = useState<PropertiesDataType[] | never[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getPropertyDetails = async () => {
      const { data, error }: PropertiesType = await getProperties();

      if (error) {
        // if (error.message === UNAUTHORIZED) {
        //   navigate('/login')
        // }
        console.log(error)
      }

      setProperties(data)
    }

    getPropertyDetails()
  }, [navigate]);

  return (
    <DashboardWrapper>
      <Heading as="h1">My Properties</Heading>
      {
        !isEmpty(properties) ? properties.map(property => (
          // ADD STYLE TO STYLED COMPONENT
          <DashboardBox key={property.uuid} sx={{borderWidth: 1, borderStyle: 'solid', borderColor: 'border.default', borderRadius: '2', p: 3}}>
            <DashboardBoxDetails>
              {property.property_name}
              {/* UPDATE CARRET */}
              <DashboardBoxLinks>
                <Link to={`/details/${property.uuid}`} className="dashboard-link-text"><Text as="p">View details {'>'}</Text></Link>
              </DashboardBoxLinks>
            </DashboardBoxDetails>
          </DashboardBox>
        )) : <>Loading...</>
      }
    </DashboardWrapper>
  )
}

export default Dashboard;
