import axios from 'axios';
import {API_URL, REFRESH_ROUTE} from "./consts";

export const axiosInstance = axios.create({
  baseURL: `${API_URL}`,
  timeout: 100000,
  headers: {'Content-Type': 'application/json;charset=UTF-8', 'Accept': 'application/json, text/plain'},
  withCredentials: true,
});

axiosInstance.interceptors.request.use(request => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return request;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => response,
  async error => {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.

      try {
        const refreshToken = localStorage.getItem('refreshToken'); // Retrieve the stored refresh token.
        // Make a request to your auth server to refresh the token.
        const response = await axios.post(`${API_URL}${REFRESH_ROUTE}`, {
          refresh: refreshToken,
        });
        const { access, refresh } = response?.data;

        // Store the new access and refresh tokens.
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        // Update the authorization header with the new access token.

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access}`;
        return axiosInstance(originalRequest); // Retry the original request with the new access token.
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
});