import { Menu } from "antd";
import { Text } from "@primer/react";
import styled from "styled-components";

export const StyledMenu = styled(Menu)`
  justify-content: center;
`;

export const StyledBackText = styled(Text)`
  display: flex;
  flex-basis: 100%;

  a {
    color: #000;
    display: inline-block;
    text-decoration: none;
  }
`;

export const AppNavBar = styled.header`
  border-bottom: 1px #000000 solid;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 16px; 
`;

export const LogoNavBarWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const UserInfoNavBarWrapper = styled.div``;

export const AppRoutesWrapper = styled.div`
  padding: 36px 0 0 0;
`;