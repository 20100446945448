export const TIMEFRAMES = {
  FIFTEEN_MINS: 'FIFTEEN_MINS',
  HOUR: 'HOUR',
  DAY: 'DAY',
}

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female'
}

export const AGE_RANGES = {
  ZERO_FIFTEEN: {
    title: '0-15',
    min: 0,
    max: 15,
  },
  SIXTEEN_THIRTYFIVE: {
    title: '16-35',
    min: 15,
    max: 35,
  },
  THIRTYSIX_FIFTYFIVE: {
    title: '36-55',
    min: 36,
    max: 55,
  },
  FIFTYSIX_SEVENTY: {
    title: '56-70',
    min: 56,
    max: 70,
  },
  SEVENTYONE_PLUS: {
    title: '71+',
    min: 71,
    max: Number.MAX_SAFE_INTEGER,
  },
}
export const UNAUTHORIZED = 'UNAUTHORIZED';

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const OBJECT_TYPE_STRING_MAP = {
  people: 'Person',
  faces: 'Face',
  dog: 'Dog',
  object: 'Object',
}