import { Box } from '@primer/react';
import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: left;

  @media screen and (orientation: portrait) {
    width: 90%;
  }

  width: 60%;

  .dashboard-link-text {
    // UPDATE COLOR WHEN DEFINED
    color: #000;
    text-decoration: none;
  }
`;

export const DashboardBox = styled(Box)`
  margin: 16px 0;
  padding: 32px;
`;

export const DashboardBoxDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DashboardBoxLinks = styled.div`
  display: flex;
  column-gap: 25px;
  text-align: center;
`