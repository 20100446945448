import * as React from 'react';
import { BarChart, BarPlot } from '@mui/x-charts/BarChart';
import { ChartsAxisHighlight, ChartsLegend, ChartsTooltip, ChartsXAxis, ChartsYAxis, LineHighlightPlot, LinePlot, MarkPlot, ResponsiveChartContainer } from '@mui/x-charts';
import { WidgetHeader } from '../demographics/styles';

type BarGraphProps = {
  seriesData: (number|null)[][];
  xAxisKeys: string[][];
  colors: string[];
  widgetHeader: string;
  barGapRatio?: number | null;
}

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];

export default function BarGraph({
  seriesData,
  xAxisKeys,
  colors,
  widgetHeader,
  barGapRatio = -1,
}: BarGraphProps) {
  return (
    <>
      <WidgetHeader>{widgetHeader}</WidgetHeader>
      <ResponsiveChartContainer
        series={seriesData.map((data, index) => (
          {
            type: 'bar',
            data: data,
            color: colors[index]
          }
        ))}
        xAxis={xAxisKeys.map((key) => (
          {
            barGapRatio,
            data: key,
            tickInterval: key,
            scaleType: 'band',
            id: 'x-axis-id',
          }
        ))}
        height={500}
      >
        <BarPlot />
        <LinePlot />
        <ChartsXAxis />
        <ChartsYAxis />
        <ChartsTooltip trigger="axis" />
      </ResponsiveChartContainer>
    </>
  );
}