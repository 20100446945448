import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography'; // Import Typography

interface MetricCardProps {
  color: string;
  children?: React.ReactNode;
  title: string;
  number: number | string;
}

const MetricCard: React.FC<MetricCardProps> = ({ color, title, number, children }) => {
  return (
    <Card sx={{
      height: 130,
      flexGrow: 0, // You might change this to control the growing behavior
      flexBasis: 'calc(25% - 16px)', // Adjusts initial width, assuming 4 cards with margin
      backgroundColor: color,
      m: 2, // Adjust margin as needed
      borderRadius: 8, // Adjust for rounded corners
    }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant="h6" component="div" sx={{ fontSize: 18, mb: 2, mt: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {title}
        </Typography>
        {number !== 0 && (
          <Typography variant="h3" component="div" sx={{ fontSize: 26, fontWeight: 'bold', mb: 2, mt: 2 }}>
            {number}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  );
};

export default MetricCard;
