import React from 'react';
import Box from '@mui/material/Box';
import MetricCard from './MetricCard'; // Make sure this import points to your MetricCard.tsx

interface MetricItem {
    title: string;
    color: string;
    number: number | string;
}

interface MetricsContainerProps {
    metrics: MetricItem[]; // Define the prop for passing the metrics array
}

const MetricsContainer: React.FC<MetricsContainerProps> = ({metrics}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', p: 1}}>
            {metrics.map((metric, index) => (
                <MetricCard key={index} title={metric.title} color={metric.color} number={metric.number} />
            ))}
        </Box>
    );
};


export default MetricsContainer;
