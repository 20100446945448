import { Box } from '@primer/react';
import styled from 'styled-components';

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: left;
  width: 60%;

  .dashboard-link-text {
    // UPDATE COLOR WHEN DEFINED
    color: #000;
    text-decoration: none;
  }
`;

export const VideoBox = styled(Box)`
  margin: 16px 0;
  padding: 32px;
`;

export const VideoBoxDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;
