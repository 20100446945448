import React, { useEffect, useState } from 'react';
import {
  DailyAverageChartWrapper,
  DetailsWrapper,
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import DailyAverageChart from '../../components/charts/DailyAverageChart';
import { UNAUTHORIZED } from '../../consts';
import domtoimage from 'dom-to-image';
import {
  PropertyType,
  getPropertyById,
  getTrafficByPropertyId,
  PropertyDemographic,
  getDemographicsByPropertyId, PropertyTraffic
} from '../../adapters/properties';
import MetricsContainer from "../../components/charts/MetricsContainer";
import PieChartContainer from "../../components/demographics/PieChartComponent";
import LineGraph from '../../components/charts/LineGraph';
import BarGraph from '../../components/charts/BarGraph';

type TrafficData = {
  weekday: number;
  weekend: number;
};

type DetailsPropType = {
  property: any;
  timeframe?: string;
}

interface DataItem {
  name: string;
  value: number;
  percentage?: string;
}

function Details(props: DetailsPropType) {
  // Replace props with GET
  const {
    property: { id = 123 },
    timeframe,
  } = props;
  const [genderMalePercentage, setGenderMalePercentage] = useState(0);
  const [genderFemalePercentage, setGenderFemalePercentage] = useState(0);
  const [ageDistribution, setAgeDistribution] = useState([12, 34, 12, 54, 12])
  const [maleAge, setMaleAge] = useState(0);
  const [femaleAge, setFemaleAge] = useState(0);
  const [dailyTrafficData, setDailyTrafficData] = useState<TrafficData>({ weekday: 0, weekend: 0 })
  const [hourlyTrafficData, setHourlyTrafficData] = useState<TrafficData>({ weekday: 0, weekend: 0 })
  const [dailyTraffic, setDailyTraffic] = useState<number[][]>([]);
  const [dailyTrafficKeys, setDailyTrafficKeys] = useState<string[][]>([])
  const [propertyName, setPropertyName] = useState('');
  const { uuid = '' } = useParams();
  const navigate = useNavigate();

  const getDetailWrapperImage = async () => {
    const detailsPage = document.getElementById('details-wrapper') as HTMLElement;

    await domtoimage.toJpeg(detailsPage, { bgcolor: '#FFFFFF' })
      .then((dataUrl: string) => {
        var link = document.createElement('a');
        link.download = `${propertyName} details.jpeg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error: Error) => {
        console.log(error)
      })

  }

  const metrics = [
    { title: "Average Weekend Hour", color: "#E5ECF6", number: hourlyTrafficData?.weekend },
    { title: "Average Week day", color: "#E3F5FF", number: dailyTrafficData?.weekday},
    { title: "Average Weekend day", color: "#E3F5FF", number: dailyTrafficData?.weekend },
    { title: "Average Weekday Hour", color: "#E5ECF6", number: hourlyTrafficData?.weekday },
  ];

  // Data for the pie charts
  const dataPieChartOne = {
    title: "Gender Ratio",
    dataItems:[
        {
          name: 'Females',
          value: genderFemalePercentage * 100,
          percentage: genderFemalePercentage,
          color: "#FF17E8",
        }, {
          name: 'Males',
          value: genderMalePercentage * 100,
          percentage: genderMalePercentage,
          color: "#95A4FC",
        }
    ]
  };

  const dataPieChartTwo = {
    title: "Age Ratios",
    dataItems:[
      { name: '0-15', value: 240, percentage: .23, color: '#FF8042' },
      { name: '16-35', value: 139, percentage: .13, color: '#FFBB28' },
      { name: '36-55', value: 456, percentage: 0.44, color: '#00C49F'  },
      { name: '56-70', value: 148, percentage: 0.15, color: '#0088FE'  },
      { name: '71+', value: 50, percentage: 0.05, color: "#A8C5DA" },
    ]};

  const dailyAvgPeople = [[8811,	null, null, null, null, null,	9056], [null, 10713,	12362,	12009,	12569,	10917, null]];
  const dailyAvgPeopleKeys = ['Sunday',	'Monday',	'Tuesday',	'Wednesday',	'Thursday',	'Friday',	'Saturday',]
  const avgWeekendTraffic = [[33,47,84,95,51,54,67,142,99,127,167,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,197,170,170,141,128,87,112,109,107,77,109,128,101,78,80,45,58,48,54,45], [null,null,null,null,null,null,null,null,null,null,null,195,220,200,176,213,191,171,163,188,189,181,174,219,236,293,210,287,204,247,247,208,235,275,219,257,218,245,262,210,274,217,223,239,265,303,396,264,317,270,242,216,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]]
  const avgWeekdayTraffic = [[33.8,63.6,84,110,58.2,53.8,70.4,127,119.2,133.4,156,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,207.6,186.4,150.4,139.6,132.8,105.4,110.4,95.2,88.4,93.8,85.4,85.8,79,93,76.8,45,64,56,37.6,37.6], [null,null,null,null,null,null,null,null,null,null,null,198.6,167,177.2,179,183,169.2,178.2,161.6,161.2,165,203.4,214.2,193,217.4,224.6,212.8,243.8,235,225.4,192,196,197.2,217.4,183.4,206,191.2,214.6,258.4,202.2,230.6,227.2,203.6,217.6,274.6,272,285.6,258.4,307.8,273,216.8,199.2,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]]
  const avgTraffic = [[9,16,16.5,21.5,21,19.5,35.5,31,41.5,57.5,68,75,76,63,83,88.5,96,102,117,136,153,156.5,156,189,149,192.5,180,208.5,209,209,202.5,186,233,195.5,224.5,219,189.5,219.5,222,208,193.5,261,206,211.5,189.5,221.5,200,193.5,183.5,184,168,149.5,155,137.5,126.5,110,110,74,79.5,73,77,50.5,44,72,53,46,74.5,62.5,39,37.5,36.5,38.5,], [33.8,63.6,84,110,58.2,53.8,70.4,127,119.2,133.4,156,198.6,167,177.2,179,183,169.2,178.2,161.6,161.2,165,203.4,214.2,193,217.4,224.6,212.8,243.8,235,225.4,192,196,197.2,217.4,183.4,206,191.2,214.6,258.4,202.2,230.6,227.2,203.6,217.6,274.6,272,285.6,258.4,307.8,273,216.8,199.2,207.6,186.4,150.4,139.6,132.8,105.4,110.4,95.2,88.4,93.8,85.4,85.8,79,93,76.8,45,64,56,37.6,37.6,]]
  const dayTrafficKeys = ['6:00','6:15','6:30','6:45','7:00','7:15','7:30','7:45','8:00','8:15','8:30','8:45','9:00','9:15','9:30','9:45','10:00','10:15','10:30','10:45','11:00','11:15','11:30','11:45','12:00','12:15','12:30','12:45','13:00','13:15','13:30','13:45','14:00','14:15','14:30','14:45','15:00','15:15','15:30','15:45','16:00','16:15','16:30','16:45','17:00','17:15','17:30','17:45','18:00','18:15','18:30','18:45','19:00','19:15','19:30','19:45','20:00','20:15','20:30','20:45','21:00','21:15','21:30','21:45','22:00','22:15','22:30','22:45','23:00','23:15','23:30','23:45']
  const avgHourlyTraffic = [[63,107,242,310.5,451,654.5,730,806.5,872,839,872,804.5,685,529,336.5,243.5,236,151.5], [291.4,309.4,607.2,706.2,670.2,775.6,898.6,848.4,804,866.4,879,1090.6,996.8,684,443.8,353.4,293.8,195.2,]]
  const hourTrafficKeys = ['6:00','7:00','8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']

  const pieCharts = [
    dataPieChartOne, dataPieChartTwo
  ]

  useEffect(() => {
    const getPropertyDetails = async () => {
      const { property_name, data, error }: PropertyType = await getPropertyById({ uuid });
      const traffic_response: PropertyTraffic = await getTrafficByPropertyId({uuid});
      if (traffic_response.error) {
        if (traffic_response.error.message === UNAUTHORIZED) {
          navigate('/login')
        }
      }
      const demo_response: PropertyDemographic = await  getDemographicsByPropertyId({uuid});
      if (demo_response.error) {
        if (demo_response.error.message === UNAUTHORIZED) {
          navigate('/login')
        }
      }
      const {
        average_daily_traffic,
        average_hourly_traffic,
        daily_traffic,
        fifteen_min_interval,
      } = traffic_response.data;

      const {
        average_age,
        gender_percentage,
        age_distribution,
      } = demo_response.data

      setPropertyName(property_name);
      setGenderMalePercentage(gender_percentage?.male ?? 0)
      setGenderFemalePercentage(gender_percentage?.female ?? 0)
      setAgeDistribution(age_distribution?.data ?? []);
      setMaleAge(average_age?.male ?? 0);
      setFemaleAge(average_age?.female ?? 0);
      setDailyTrafficData(average_daily_traffic);
      setHourlyTrafficData(average_hourly_traffic);
      setDailyTraffic([...dailyTraffic, daily_traffic.map(day => day?.count)])
      setDailyTrafficKeys([...dailyTrafficKeys, daily_traffic.map((day, index) => day?.day)])
    }

    getPropertyDetails();
  }, [timeframe]);

  return (
      <DetailsWrapper>
        <MetricsContainer metrics={metrics} />
        {/* <DailyAverageChartWrapper >
          <DailyAverageChart dailyTraffic={dailyTraffic} dailyTrafficKeys={dailyTrafficKeys} />
        </DailyAverageChartWrapper> */}
        {/* <DailyAverageChartWrapper >
          <LineGraph seriesData={dailyTraffic} xAxisKeys={dailyTrafficKeys} />
        </DailyAverageChartWrapper> */}

        <DailyAverageChartWrapper>
          <LineGraph
            seriesData={[[8811,	10713, null, null, null, 10917,	9056], [null, 10713,	12362,	12009,	12569,	10917, null]]}
            xAxisKeys={[dailyAvgPeopleKeys]}
            colors={['#046662', '#34E1A5']}
            widgetHeader={'Daily Average People Count'}
          />
        </DailyAverageChartWrapper>
        <BarGraph
          widgetHeader={'Daily Average People Count'}
          seriesData={dailyAvgPeople}
          xAxisKeys={[dailyAvgPeopleKeys]}
          colors={['#046662', '#34E1A5']}
        />
        <BarGraph
          widgetHeader={'Average Weekend Traffic'}
          seriesData={avgWeekendTraffic}
          xAxisKeys={[dayTrafficKeys]}
          colors={['#046662', '#34E1A5']}
        />
        <BarGraph
          widgetHeader={'Average Weekday Traffic'}
          seriesData={avgWeekdayTraffic}
          xAxisKeys={[dayTrafficKeys]}
          colors={['#046662', '#34E1A5']}
        />
        <BarGraph
          widgetHeader={'Average Hourly Traffic'}
          seriesData={avgHourlyTraffic}
          xAxisKeys={[hourTrafficKeys]}
          colors={['#046662', '#34E1A5']}
          barGapRatio={null}
        />
        <BarGraph
          widgetHeader={'Average Traffic'}
          seriesData={avgTraffic}
          xAxisKeys={[dayTrafficKeys]}
          colors={['#046662', '#34E1A5']}
          barGapRatio={null}
        />
      <PieChartContainer piecharts={pieCharts}/>
      </DetailsWrapper>
  )
}

export default Details
