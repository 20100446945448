import { Box } from "@primer/react";
import styled from "styled-components";

export const DetailsWrapper = styled.div`
  margin: auto;
  text-align: left;
  width: 90%;
  border: 1px solid #e0e0e0; // Light grey border
  border-radius: 8px; // Optional: adds rounded corners
  padding: 16px; // Adjust padding as necessary
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow
`;

export const DetailsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DetailsDataWrapper = styled.div`
  display: grid;

  @media screen and (orientation: portrait) {
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas: 
      "daily-average"
      "daily-traffic"
      "fifteen-min-interval"
      "hourly-traffic"
      "gender-demographic"
      "age-data"
      "profile-data";
  }
  gap: 32px;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-template-areas: 
    "daily-average daily-average daily-average daily-average daily-average daily-traffic daily-traffic daily-traffic"
    "fifteen-min-interval fifteen-min-interval fifteen-min-interval fifteen-min-interval fifteen-min-interval hourly-traffic hourly-traffic hourly-traffic"
    "gender-demographic gender-demographic age-data age-data age-data age-data profile-data profile-data";
`;

export const DailyAverageChartWrapper = styled(Box)`
  grid-area: daily-average;
  padding: 32px;
`;

export const DemographicBreakdownWrapper = styled(Box)`
  grid-area: demographic-breakdown;
  padding: 32px;
`;

export const FifteenMinIntervalWrapper = styled(Box)`
  grid-area: fifteen-min-interval;
  padding: 32px;
`;

export const GenderDemographicsWrapper = styled(Box)`
  grid-area: gender-demographic;
  padding: 32px;
`;

export const AgeDataWrapper = styled(Box)`
  grid-area: age-data;
  padding: 32px;
`;

export const ProfileDataWrapper = styled(Box)`
  grid-area: profile-data;
  padding: 32px;
`;

export const AverageDailyTrafficWrapper = styled(Box)`
  grid-area: daily-traffic;
  padding: 32px;
`;
export const AverageHourlyTrafficWrapper = styled(Box)`
  grid-area: hourly-traffic;
  padding: 32px;
`;
