import { Text, theme } from "@primer/react";
import { Image } from "antd";
import styled from "styled-components";

type StyledDemographicDataWrapperProps = {
  position?: string;
}

export const WidgetHeader = styled.h6`
  font-size: 1.25rem;
  color: #4A4A4A; // Adjust the color to fit your theme
  margin-bottom: 40px; // Increased bottom margin for more space
  font-weight: bold;
`;


export const StyledDemographicDataWrapper = styled.div`
  border-right-style: ${(props: StyledDemographicDataWrapperProps) =>
    props.position === 'middle' ? 'solid' : 'none'};
  border-left-style: ${(props: StyledDemographicDataWrapperProps) =>
    props.position === 'middle' ? 'solid' : 'none'};
  border-color: rgba(5, 5, 5, 0.06);
`;

export const GenderBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

export const GenderDataWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%
`;

export const StyledGenderWidget = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`;

export const StyledDemographicBreakdownWrapper = styled.div`
  padding: 25px;
`;

export const StyledGenderData = styled.div`
  flex: 2;
`;

export const GenderWidgetImage = styled(Image)`
  flex: 1;
`;

export const ProfileBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

export const ProfileDataWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%
`;

export const StyledProfileWidget = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`;

export const AgeWidgetImageWrapper = styled.div`
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 500px;
`;

export const ChartWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChartTitle = styled.h2`
  margin: 0 0 20px 0;
`;