import React from 'react';
import { LineHighlightPlot } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { ChartsAxisHighlight, ChartsTooltip, ChartsXAxis, ChartsYAxis } from '@mui/x-charts';
import {ChartsLegend} from '@mui/x-charts/ChartsLegend';
import { isEmpty } from 'lodash';
import { StyledChartWrapper } from '../styles';
import { WidgetHeader } from '../demographics/styles';

type LineGraphProps = {
  seriesData: (number|null)[][];
  xAxisKeys: string[][];
  colors: string[];
  widgetHeader: string;
}

export function LineGraph({ colors, seriesData, xAxisKeys, widgetHeader }: LineGraphProps) {
  return (
    !isEmpty(seriesData) ?
      <StyledChartWrapper>
        <WidgetHeader>{widgetHeader}</WidgetHeader>
          <ResponsiveChartContainer
            series={seriesData.map((data, index) => (
              {
                type: 'line',
                data: data,
                color: colors[index]
              }
            ))}
            xAxis={xAxisKeys.map((key) => (
              {
                data: key,
                tickInterval: key,
                scaleType: 'point',
                id: 'x-axis-id',
                valueFormatter: (date) => date
              }
            ))}
              height={500}
            >
              <LinePlot />
              <ChartsXAxis />
              <ChartsYAxis />
              <MarkPlot />
              <ChartsLegend />
              <LineHighlightPlot />
              <ChartsAxisHighlight x="line" />
              <ChartsTooltip trigger="axis" />
            </ResponsiveChartContainer>
      </StyledChartWrapper> : <p>...Loading</p>
  )
}

export default LineGraph;
