import React, { useState } from 'react';
import { FormControl, Octicon } from '@primer/react'
import { DiamondIcon } from '@primer/octicons-react'
import { authenticationAdapters } from '../../../adapters';
import { AuthBox, AuthButton, AuthFormText, AuthFormWrapper, AuthHeader, AuthHeaderText, AuthSignUpText, AuthTextError, AuthTextInput, AuthWrapper } from '../styles';
import { Link, Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

export type HandleRegisterType = {
  email: string,
  username: string,
  password: string,
  password2: string,
  firstName: string,
  lastName: string,
}



const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [errors, setErrors] = useState<any>({})
  const handleRegister = async ({
    email,
    username,
    password,
    password2,
    firstName,
    lastName
  }: HandleRegisterType) => {
    try {
      const resp: any = await authenticationAdapters.register({
        email,
        username,
        password,
        password2,
        firstName,
        lastName
      });
      // setAuthenticatedUser(resp);

    } catch (err) {
      setErrors(err)
    }
  }

  return (
    <AuthWrapper>
      {
        // !isEmpty(authenticatedUser) ?
        //   (
        //     <Navigate to={'/'} replace />
        //   ) : (
            <AuthBox sx={{borderWidth: 1, borderStyle: 'solid', borderColor: 'border.default', borderRadius: '2', p: 3}}>
              <AuthHeader>
                <Octicon icon={DiamondIcon} size={16}/>
              <AuthHeaderText>Streetsmart.ai</AuthHeaderText>
              </AuthHeader>
              <AuthFormWrapper>
                <AuthFormText>Sign up</AuthFormText>
                <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                  <FormControl.Label>Email</FormControl.Label>
                  <AuthTextInput value={email} onChange={(e) => setEmail(e.currentTarget.value)} validationStatus={errors.email && 'error'} />
                  { errors && errors.email && errors.email.map((error: string) => <AuthTextError>{error}</AuthTextError>)}
                </FormControl>
                <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                  <FormControl.Label>Username</FormControl.Label>
                  <AuthTextInput value={username} onChange={(e) => setUsername(e.currentTarget.value)} validationStatus={errors.username && 'error'} />
                  { errors && errors.username && errors.username.map((error: string) => <AuthTextError>{error}</AuthTextError>)}
                </FormControl>
                <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                  <FormControl.Label>Password</FormControl.Label>
                  <AuthTextInput type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} validationStatus={errors.password && 'error'} />
                  { errors && errors.password && errors.password.map((error: string) => <AuthTextError>{error}</AuthTextError>)}
                </FormControl>
                <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                  <FormControl.Label>Confirm password</FormControl.Label>
                  <AuthTextInput type="password" value={password2} onChange={(e) => setPassword2(e.currentTarget.value)} validationStatus={errors.password2 && 'error'} />
                  { errors && errors.password && errors.password.map((error: string) => <AuthTextError>{error}</AuthTextError>)}
                </FormControl>
                <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                  <FormControl.Label>First name</FormControl.Label>
                  <AuthTextInput value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} validationStatus={errors.firstName && 'error'} />
                  { errors && errors.firstName && errors.firstName.map((error: string) => <AuthTextError>{error}</AuthTextError>)}
                </FormControl>
                <FormControl sx={{ width: '100%', padding: '0 0 24px 0' }}>
                  <FormControl.Label>Last name</FormControl.Label>
                  <AuthTextInput value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} validationStatus={errors.lastName && 'error'} />
                  { errors && errors.lastName && errors.lastName.map((error: string) => <AuthTextError>{error}</AuthTextError>)}
                </FormControl>
                <AuthButton onClick={() => handleRegister({ email, username, password, password2, firstName, lastName })}>Sign up</AuthButton>
              </AuthFormWrapper>
              <AuthSignUpText>Already have an account? <Link to="/login">Log in</Link></AuthSignUpText>
            </AuthBox>
          // )
      }
    </AuthWrapper>
  )
}

export default Register
