import React, { useState } from 'react';
import {ThemeProvider, BaseStyles, Heading, Octicon} from '@primer/react';
import { DiamondIcon } from '@primer/octicons-react';
import './App.css';
import Login from './containers/Authentication/Login';
import { Routes, Route } from "react-router-dom";
import Register from './containers/Authentication/Register';
import Dashboard from './containers/Dashboard';
import Details from './containers/Details';
import { AuthenticationContext } from './context/Authentication';
import {JWTLoginResponseType, LoginResponseType} from './adapters/authentication';
import VideoPlayer from "./containers/Audit";
import VideosList from "./containers/Videos/List"
import AuditImageList from "./containers/Videos/Read"
import ObjectTrackingList from "./containers/Tracking/List";
import { AppNavBar, AppRoutesWrapper, LogoNavBarWrapper, UserInfoNavBarWrapper } from './styles';
import { isEmpty } from 'lodash';
import GenderAudit from './containers/GenderAudit';

function App() {
  const [authenticatedUser, setAuthenticatedUser] = useState<LoginResponseType |  null>(null);
  const [authenticationToken, setAuthenticationToken] = useState<JWTLoginResponseType |  null>(null);

  return (
    <ThemeProvider>
      <BaseStyles>
      <AuthenticationContext.Provider value={authenticatedUser}>
        <AppNavBar>
          <LogoNavBarWrapper>
            <Octicon icon={DiamondIcon} size={16}/>
            <Heading sx={{fontSize: 3, mb: 3}}>Smartstreet.ai</Heading>
          </LogoNavBarWrapper>
          <UserInfoNavBarWrapper>
            {!isEmpty(authenticatedUser) && authenticatedUser.username}
          </UserInfoNavBarWrapper>
        </AppNavBar>
        <AppRoutesWrapper className="App">
          <Routes>
            <Route path="/login" element={<Login authenticationToken={authenticationToken} setAuthenticationToken={setAuthenticationToken}/>} />
            <Route path="/register" element={<Register />} />
            {/* <Route index element={<NavigationHeader />}> */}
              <Route path="/" element={<GenderAudit/>} />
              <Route path="/details" element={<Dashboard />} />
              <Route path="/details/:uuid" element={<Details property={{ id: 123, address: '123 Madision Ave'}} />} />
              <Route path="/audit/" element={<VideosList/>} />
              <Route path="/audit/:video_name/" element={<AuditImageList/>} />
              {/*<Route path="/tracking/:uuid/:type" element={<ObjectTrackingList/>} />*/}
              {/*<Route path="/tracking/:uuid/objects/:object_id" element={<VideoPlayer/>} />*/}
            {/* </Route> */}

          </Routes>
        </AppRoutesWrapper>
      </AuthenticationContext.Provider>
      </BaseStyles>
    </ThemeProvider>
  );
}

export default App;
