export const API_URL = process.env.API_URL || 'https://apis.smartstreet.ai/';
// export const API_URL = 'http://localhost:8000/';
export const AUTH_ROUTE = 'api/auth/';
// export const LOGIN_ROUTE = 'login/';
export const TOKEN_ROUTE = 'token/'
export const REFRESH_ROUTE = `${AUTH_ROUTE}${TOKEN_ROUTE}refresh/`
export const REGISTER_ROUTE = 'register/';
export const PROPERTY_ROUTE = 'property/';
export const PROPERTIES_ROUTE = 'properties/';
export const VIDEOS_ROUTE = 'videos/';
export const STATS_ROUTE = `stats`
export const MEDIA_ROUTE = 'media/'
export const OBJECT_ROUTE = '/objects'
export const UPLOAD_ROUTE = '/upload'
export const IMAGE_ROUTE = 'images/'
