import { axiosInstance } from "./instance";
import { UNAUTHORIZED } from "../consts";



export async function getGenderAuditImages(): Promise<any> {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `images/label`,
    })
    return { data: response?.data, error: null };
  } catch (err: any) {
    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED) }
    }

    return { data: {}, error: new Error(`Error getting images to audit gender: ${err}`) };
  }
};


export async function postGenderAudit(key: string, label: string) {
  const requestBody = {
    label,
    key
  }
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `images/label`,
      data: requestBody
    })
    return { data: response?.data, error: null };
  } catch (err: any) {
    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED) }
    }

    return { data: {}, error: new Error(`Error posting images to audit ${key}: ${err}`) };
  }
};

