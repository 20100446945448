import React, { useEffect, useState } from 'react';
import { VideoBox, VideoBoxDetails, VideoWrapper } from './styles'
import { Heading, Octicon, Text, TreeView } from '@primer/react';
import { DeviceCameraVideoIcon } from '@primer/octicons-react'
import {Link, useNavigate, useParams} from 'react-router-dom';
import { isEmpty } from 'lodash';
import { UNAUTHORIZED } from '../../../consts';
import {getVideosForAudit, VideoDataType, VideoType} from "../../../adapters/videos";
import { StyledBackText } from '../../../styles';

const filterVideos = (videos: any) => {
  const filteredVideos: any = {};

  videos.forEach((video: any) => {
    const cameraId = `${video.id.slice(0,2)}`;
    const year = video.timestamp_string.slice(0,4);
    const month = video.timestamp_string.slice(4,6);
    const day = video.timestamp_string.slice(6,8);
    const fullMonth = Intl.DateTimeFormat('en', { month: 'long' }).format(new Date().setMonth(month))

    filteredVideos[cameraId] = !isEmpty(filteredVideos[cameraId]) ? { ...filteredVideos[cameraId] } : {};
    filteredVideos[cameraId][year] = !isEmpty(filteredVideos[cameraId][year]) ? { ...filteredVideos[cameraId][year] } : {};
    filteredVideos[cameraId][year][fullMonth] = !isEmpty(filteredVideos[cameraId][year][fullMonth]) ? { ...filteredVideos[cameraId][year][fullMonth] } : {};
    filteredVideos[cameraId][year][fullMonth][day] = !isEmpty(filteredVideos[cameraId][year][fullMonth][day]) ? [ ...filteredVideos[cameraId][year][fullMonth][day], video]  : [video];
  })

  return filteredVideos
}

const VideosList = () => {
  const [videos, setVideos] = useState<any | {}>({});
  const [showAudited, setShowAudited] = useState(false);

  const toggleShowAudited = () => {
    setShowAudited(!showAudited);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const getVideos = async () => {
      const { data, error }: VideoType = await getVideosForAudit();
      if (error) {
        if (error.message === UNAUTHORIZED) {
          navigate('/login')
        }
      }

      setVideos(filterVideos(data))
    }

    getVideos()
  }, [navigate]);

  return (
      <VideoWrapper>
        <StyledBackText onClick={() => navigate(-1)}>{'<'} Go Back</StyledBackText>
        <Heading as="h1">Audit Videos</Heading>
        <button onClick={toggleShowAudited}>
          {showAudited ? 'Hide Audited Videos' : 'Show Audited Videos'}
        </button>
        <TreeView aria-label="Files">
          {
            !isEmpty(videos) ? (
                Object.keys(videos).map((camera) => (
                    <TreeView.Item id="camera" key={camera} defaultExpanded={true}>
                      <TreeView.LeadingVisual>
                        <TreeView.DirectoryIcon />
                      </TreeView.LeadingVisual>
                      {camera}
                      <TreeView.SubTree>
                        {Object.keys(videos[camera]).map((year) =>
                            Object.keys(videos[camera][year]).map((month) =>
                                Object.keys(videos[camera][year][month]).map((day) =>
                                    Object.keys(videos[camera][year][month][day]).map((video) => {
                                      const videoName = videos[camera][year][month][day][video].id;
                                      const isAudited = videos[camera][year][month][day][video].is_audited;
                                      const color = isAudited ? 'red' : 'blue';

                                      if (!showAudited && isAudited) {
                                        return null; // Skip rendering if audited videos are hidden
                                      }

                                      return (
                                          <TreeView.Item id="video" key={videoName}>
                                            <TreeView.LeadingVisual>
                                              <Octicon icon={DeviceCameraVideoIcon} size={16} color={color} />
                                            </TreeView.LeadingVisual>
                                            <Link to={`/audit/${videoName}/`} className="dashboard-link-text">
                                              {videoName}
                                            </Link>
                                          </TreeView.Item>
                                      );
                                    })
                                )
                            )
                        )}
                      </TreeView.SubTree>
                    </TreeView.Item>
                ))
            ) : <>Loading...</>
          }
        </TreeView>
      </VideoWrapper>
  );

}

export default VideosList;
